import { memo } from "react";
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const Card = ({ slotProps, ...restProps }) => (
    <MuiCard
        {...restProps}
    >
        <CardMedia
            {...slotProps.media}
        />
        <CardContent
            {...slotProps.content}
        >
            <Typography
                gutterBottom
                component='div'
                {...slotProps.title}
            />
            <Typography
                component='div'
                {...slotProps.description}
            />
        </CardContent>
    </MuiCard>
);

export default memo(Card);
