import { memo } from "react";
import Box from "@mui/material/Box";
import { Card, LinearProgress, Slider } from "../components";

const Sliderable = ({
    slotProps = {},
    slides = [],
    ...restProps
}) => (
    <Box
        {...restProps}
    >
        <LinearProgress
            {...slotProps.progress}
        />
        <Slider
            {...slotProps.slider}
        >
            {slides.map(({ title, description, ...restProps }, index) => (
                <Card
                    {...slotProps.slide}
                    key={index}
                    slotProps={{
                        ...slotProps.slide,
                        media: {
                            ...restProps,
                            title,
                            ...slotProps.slide?.media
                        },
                        content: slotProps.slide?.content,
                        title: {
                            children: title,
                            ...slotProps.slide?.title
                        },
                        description: {
                            children: description,
                            ...slotProps.slide?.description
                        }
                    }}
                />
            ))}
        </Slider>
    </Box>
);

export default memo(Sliderable);
