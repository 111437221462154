export default function useStyles() {
    return {
        root: {
            maxWidth: 'calc(100vw - 40px)',
            '@media (min-width: 1075px)': {
                maxWidth: 1043
            }
        },

        content: {
            display: 'none'
        },

        slider: {
            '& .swiper-pagination-bullets': {
                [`@media (min-width: 601px)`]: {
                    bottom: 25
                }
            }
        },

        slideMedia: {
            height: 220,

            [`@media (min-width: 601px)`]: {
                height: 308
            }
        },

        slideTitle: {
            fontSize: 24,

            [`@media (min-width: 601px)`]: {
                fontSize: 28
            }
        },

        slideDescription: {
            mb: 4
        }
    };
};
