import { memo } from "react";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { Sliderable } from "../layouts";

const Slides = ({
    sx,
    children,
    slides = [],
    slotProps = {}
}) => (
    <Sliderable
        slides={slides}
        sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            justifyContent: 'center',
            gap: 13.5,
            width: '100%',
            mx: 'auto',
            px: '20px',
            ...sx
        }}
        slotProps={{
            ...slotProps,
            progress: {
                children,
                ...slotProps.progress,
                sx: {
                    mt: 5.875,
                    display: 'grid',
                    gap: 2,
                    textAlign: 'center',
                    fontFamily: 'inherit',
                    fontSize: '1rem',
                    lineHeight: '150%',
                    letterSpacing: '.12px',
                    ...slotProps.progress?.sx
                },
                ProgressProps: {
                    ...slotProps.progress?.ProgressProps,
                    sx: {
                        bgcolor: 'var(--color-pink-tone)',
                        borderRadius: 2,
                        [`& .${linearProgressClasses.bar1Indeterminate}`]: {
                            backgroundImage: 'linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%)'
                        },
                        [`& .${linearProgressClasses.bar2Indeterminate}`]: {
                            backgroundImage: 'linear-gradient(92.14deg, #F35597 1.79%, #7868C7 99.81%)'
                        },
                        ...slotProps.progress?.ProgressProps?.sx
                    }
                }
            },
            slider: {
                ...slotProps.slider,
                config: {
                    loop: true,
                    centeredSlides: true,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                        stopOnLastSlide: true
                    },
                    pagination: {
                        dynamicBullets: true
                    },
                    ...slotProps.slider?.config
                },
                sx: {
                    width: 'inherit',
                    maxWidth: 'inherit',
                    '& .swiper-pagination-bullet-active': {
                        bgcolor: 'var(--color-cn-light-pink)'
                    },
                    ...slotProps.slider?.sx
                }
            },
            slide: {
                elevation: 0,
                ...slotProps.slide,
                sx: {
                    width: '100%',
                    borderRadius: 5,
                    border: '1px solid var(--color-light-grey)',
                    ...slotProps.slide?.sx
                },
                media: {
                    ...slotProps.slide?.media,
                    sx: {
                        height: 308,
                        ...slotProps.slide?.media?.sx
                    }
                },
                content: {
                    ...slotProps.slide?.content,
                    sx: {
                        px: 3,
                        py: 2,
                        ...slotProps.slide?.content?.sx
                    }
                },
                title: {
                    ...slotProps.slide?.title,
                    sx: {
                        fontFamily: 'inherit',
                        fontSize: 28,
                        fontWeight: 500,
                        lineHeight: '120%',
                        textAlign: 'center',
                        ...slotProps.slide?.title?.sx
                    }
                },
                description: {
                    ...slotProps.slide?.description,
                    sx: {
                        fontFamily: 'inherit',
                        fontSize: '1rem',
                        lineHeight: '120%',
                        textAlign: 'center',
                        ...slotProps.slide?.description?.sx
                    }
                }
            }
        }}
    />
);

export default memo(Slides);
