import { memo } from "react";
import MuiLinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const LinearProgress = ({
    children,
    ProgressProps,
    ...props
}) => (
    <Box
        {...props}
    >
        <MuiLinearProgress
            variant='indeterminate'
            {...ProgressProps}
        />
        {children}
    </Box>
);

export default memo(LinearProgress);
