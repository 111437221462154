import { memo, Children } from "react";
import SwiperCore, { Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Box from "@mui/material/Box";

SwiperCore.use([Autoplay, Pagination, A11y]);

const Slider = ({ config, children, ...restProps }) => (
    <Box
        {...restProps}
    >
        <Swiper
            {...config}
        >
            {Children.map(children, (child, index) => (
                <SwiperSlide
                    key={index}
                >
                    {child}
                </SwiperSlide>
            ))}
        </Swiper>
    </Box>
);

export default memo(Slider);
