import filler from "@/content/filler.json";

export default function useContent({ slides = filler, isMobile } = {}) {
    return slides.map(({ alt, title, description, images }) => ({
        image: images[Number(!isMobile)],
        alt,
        title,
        description
    }));
};
