import { memo } from "react";
import { Slides } from "@/components/Filler";
import {
    useContent,
    useStyles
} from './core';

const SlidablePromoFiller = props => {
    const slides = useContent(props);
    const {
        root,
        content,
        slider,
        slideMedia,
        slideTitle,
        slideDescription
    } = useStyles();

    return (
        <Slides
            sx={root}
            slides={slides}
            slotProps={{
                progress: {
                    sx: content
                },
                slider: {
                    sx: slider
                },
                slide: {
                    media: {
                        sx: slideMedia
                    },
                    title: {
                        sx: slideTitle
                    },
                    description: {
                        sx: slideDescription
                    }
                }
            }}
        />
    );
};

export default memo(SlidablePromoFiller);
