export const withAdjustCalendarSelectedDates = (element, {
    selector = '.CalendarDay__selected_start',
    closest = selector,
    behavior = 'smooth',
    ...restScrollSettings
} = {}) => () => (
    element
        ?.querySelector(selector)
        ?.closest(closest)
        .scrollIntoView({
            ...restScrollSettings,
            behavior,
        })
);
